import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import Sheet from "@mui/joy/Sheet";

import MessagesPane from "./MessagesPane";
import ConsultationsPane from "./ConsultationsPane";
import { ChatProps, MessageProps } from "../types";
import { listConsultations } from "../api/apiService";
import { CssVarsProvider } from "@mui/joy/styles";
import CssBaseline from "@mui/joy/CssBaseline";
import Box from "@mui/joy/Box";
import Sidebar from "./Sidebar";
import Header from "./Header";

export default function ConsultationHome() {
  const { consultationId = "" } = useParams();
  const [selectedConsultation, setSelectedConsultation] =
    React.useState<ChatProps | null>(null);
  const [consultations, setConsultations] = React.useState<ChatProps[]>([]);
  const [chatMessages, setChatMessages] = React.useState<MessageProps[]>([]);

  useEffect(() => {
    const fetchConsultations = async () => {
      const data = await listConsultations();
      setConsultations(data);
    };
    fetchConsultations();
  }, []);

  return (
    <CssVarsProvider disableTransitionOnChange>
      <CssBaseline />
      <Box sx={{ display: "flex", minHeight: "100dvh" }}>
        <Sidebar />
        <Header />
        <Box component="main" className="MainContent" sx={{ flex: 1 }}>
          <Sheet
            sx={{
              flex: 1,
              width: "100%",
              mx: "auto",
              pt: { xs: "var(--Header-height)", md: 0 },
              display: "grid",
              gridTemplateColumns: {
                xs: "1fr",
                sm: "minmax(min-content, min(30%, 400px)) 1fr",
              },
            }}
          >
            <Sheet
              sx={{
                position: { xs: "fixed", sm: "sticky" },
                transform: {
                  xs: "translateX(calc(100% * (var(--MessagesPane-slideIn, 0) - 1)))",
                  sm: "none",
                },
                transition: "transform 0.4s, width 0.4s",
                zIndex: 100,
                width: "100%",
                top: 52,
              }}
            >
              <ConsultationsPane
                consultations={consultations}
                selectedConsultationId={selectedConsultation?.id}
                setSelectedConsultation={setSelectedConsultation}
                setChatMessages={setChatMessages}
              />
            </Sheet>
            <MessagesPane
              consultations={consultations}
              setConsultations={setConsultations}
              selectedConsultation={selectedConsultation}
              setSelectedConsultation={setSelectedConsultation}
              chat={selectedConsultation}
              chatMessages={chatMessages}
              setChatMessages={setChatMessages}
            />
          </Sheet>
        </Box>
      </Box>
    </CssVarsProvider>
  );
}
