import * as React from "react";
import Box from "@mui/joy/Box";
import ListDivider from "@mui/joy/ListDivider";
import ListItem from "@mui/joy/ListItem";
import ListItemButton, { ListItemButtonProps } from "@mui/joy/ListItemButton";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { ChatProps, MessageProps, UserProps } from "../types";
import { toggleMessagesPane } from "../utils";
import { timeAgo } from "../api/utils";

type ChatListItemProps = ListItemButtonProps & {
  id: string;
  unread?: boolean;
  sender: "Assistant" | "You";
  title: string;
  lastMessage: string;
  createdAt: string;
  messages: MessageProps[];
  selectedConsultationId?: string;
  setSelectedConsultation: (chat: ChatProps) => void;
};

function formatTitle(createdAt: string) {
  const createdTime = new Date(createdAt);
  const month = createdTime.getMonth() + 1; // months are 0-indexed
  const day = createdTime.getDate();
  return `Consultation ${day}/${month}`;
}

export default function ChatListItem(props: ChatListItemProps) {
  const {
    id,
    sender,
    messages,
    title,
    lastMessage,
    createdAt,
    selectedConsultationId,
    setSelectedConsultation,
  } = props;
  const selected = selectedConsultationId === id;
  return (
    <React.Fragment>
      <ListItem>
        <ListItemButton
          onClick={() => {
            toggleMessagesPane();
            setSelectedConsultation({
              id,
              sender,
              title,
              messages,
              createdAt,
              lastMessage,
            });
            window.history.replaceState({}, "", `/consultations/${id}`);
          }}
          selected={selected}
          color="neutral"
          sx={{ flexDirection: "column", alignItems: "initial", gap: 1 }}
        >
          <Stack
            direction="row"
            spacing={1.5}
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Typography level="title-sm" noWrap>
              {formatTitle(createdAt)}
            </Typography>
            <Typography level="body-xs" noWrap sx={{ color: "text.secondary" }}>
              {timeAgo(createdAt)}
            </Typography>
          </Stack>
          <Typography
            level="body-sm"
            sx={{
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {lastMessage}
          </Typography>
        </ListItemButton>
      </ListItem>
      <ListDivider sx={{ margin: 0 }} />
    </React.Fragment>
  );
}
