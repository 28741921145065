import * as React from "react";
import Stack from "@mui/joy/Stack";
import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";
import List from "@mui/joy/List";
import IconButton from "@mui/joy/IconButton";
import AddIcon from "@mui/icons-material/Add";
import ChatListItem from "./ChatListItem";
import { ChatProps, MessageProps } from "../types";
import { useNavigate } from "react-router-dom";
import { toggleMessagesPane } from "../utils";

type ConsultationsPaneProps = {
  consultations: ChatProps[];
  selectedConsultationId: string | undefined;
  setSelectedConsultation: (chat: ChatProps | null) => void;
  setChatMessages: (messages: MessageProps[]) => void;
};

export default function ConsultationsPane(props: ConsultationsPaneProps) {
  const navigate = useNavigate();

  const {
    consultations,
    setSelectedConsultation,
    selectedConsultationId,
    setChatMessages,
  } = props;

  const handleNewConversation = () => {
    navigate("/consultations/new");
    setSelectedConsultation(null);
    setChatMessages([]);
    toggleMessagesPane();
  };
  return (
    <Sheet
      sx={{
        borderRight: "1px solid",
        borderColor: "divider",
        height: { sm: "calc(100dvh - var(--Header-height))", md: "100dvh" },
        overflowY: "auto",
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          p: 2,
          pb: 1.5,
        }}
      >
        <Typography
          component="h1"
          sx={{
            fontSize: { xs: "md", md: "lg" },
            fontWeight: "lg",
            mr: "auto",
          }}
        >
          Consultations
        </Typography>
        <IconButton
          component="label"
          size="sm"
          variant="plain"
          color="primary"
          onClick={handleNewConversation}
        >
          <AddIcon />
        </IconButton>
      </Stack>
      <List
        sx={{
          py: 0,
          "--ListItem-paddingY": "0.75rem",
          "--ListItem-paddingX": "1rem",
        }}
      >
        {consultations.map((consultation) => (
          <ChatListItem
            key={consultation.id}
            {...consultation}
            setSelectedConsultation={setSelectedConsultation}
            selectedConsultationId={selectedConsultationId}
          />
        ))}
      </List>
    </Sheet>
  );
}
