import * as React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import ConsultationHome from "./components/ConsultationHome";
import SignInPage from "./components/SignInPage";
import SignUpPage from "./components/SignUpPage";
import ProfileContainer from "./components/ProfileContainer";

export default function JoyMessagesTemplate() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<SignInPage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/profile" element={<ProfileContainer />} />
        <Route
          path="/consultations/:consultationId"
          element={<ConsultationHome />}
        />
      </Routes>
    </Router>
  );
}
