import * as React from "react";
import Avatar from "@mui/joy/Avatar";
import Box from "@mui/joy/Box";
import IconButton from "@mui/joy/IconButton";
import Stack from "@mui/joy/Stack";
import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";
import CelebrationOutlinedIcon from "@mui/icons-material/CelebrationOutlined";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import InsertDriveFileRoundedIcon from "@mui/icons-material/InsertDriveFileRounded";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import { MessageProps } from "../types";
import ReactMarkdown from "react-markdown";
import Modal from "@mui/joy/Modal/Modal";

type ChatBubbleProps = MessageProps & {
  variant: "sent" | "received";
};

export default function ChatBubble(props: ChatBubbleProps) {
  const { content, variant, attachment = undefined, sender } = props;
  const isSent = variant === "sent";
  const [isHovered, setIsHovered] = React.useState<boolean>(false);
  const [isLiked, setIsLiked] = React.useState<boolean>(false);
  const [isDisliked, setIsDisliked] = React.useState<boolean>(false);
  const [isImageModalOpen, setImageModalOpen] = React.useState(false);

  const handleImageClick = () => {
    setImageModalOpen(true);
  };

  const handleClose = () => {
    setImageModalOpen(false);
  };

  return (
    <Box sx={{ maxWidth: "80%", minWidth: "auto" }}>
      <Stack
        direction="row"
        spacing={2}
        sx={{ justifyContent: "space-between", mb: 0.25 }}
      >
        <Typography level="body-xs">{sender}</Typography>
      </Stack>
      {attachment?.fileName ? (
        <>
          <Box
            component="img"
            src={attachment.url}
            alt={attachment.fileName}
            onClick={handleImageClick}
            sx={{
              maxWidth: "100%",
              maxHeight: 200,
              borderRadius: "8px",
              cursor: "pointer",
            }}
          />
          <Modal open={isImageModalOpen} onClose={handleClose}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                maxWidth: "90%",
                maxHeight: "90%",
                outline: "none",
                bgcolor: "background.paper",
                p: 2,
                borderRadius: "md",
              }}
            >
              <img
                src={attachment.url}
                alt={attachment.fileName}
                style={{ width: "100%", height: "auto", borderRadius: "8px" }}
              />
            </Box>
          </Modal>
        </>
      ) : (
        <Box
          sx={{ position: "relative" }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Sheet
            color={isSent ? "primary" : "neutral"}
            variant={isSent ? "solid" : "soft"}
            sx={[
              {
                p: 1.25,
                borderRadius: "lg",
              },
              isSent
                ? {
                    borderTopRightRadius: 0,
                  }
                : {
                    borderTopRightRadius: "lg",
                  },
              isSent
                ? {
                    borderTopLeftRadius: "lg",
                  }
                : {
                    borderTopLeftRadius: 0,
                  },
              isSent
                ? {
                    backgroundColor: "var(--joy-palette-primary-solidBg)",
                  }
                : {
                    backgroundColor: "background.body",
                  },
            ]}
          >
            <Box
              sx={{
                color: isSent
                  ? "var(--joy-palette-common-white)"
                  : "var(--joy-palette-text-primary)",
                padding: 0,
                margin: 0,
                fontSize: "14px",
              }}
            >
              <ReactMarkdown
                components={{
                  h3: ({ node, ...props }) => (
                    <h3
                      style={{ paddingTop: "1px", paddingBottom: "1px" }}
                      {...props}
                    />
                  ),
                  p: ({ node, ...props }) => (
                    <p style={{ margin: 0 }} {...props} />
                  ),
                  ol: ({ node, ...props }) => (
                    <ol
                      style={{ margin: "6px", paddingLeft: "10px" }}
                      {...props}
                    />
                  ),
                  ul: ({ node, ...props }) => (
                    <ul
                      style={{ margin: "6px", paddingLeft: "10px" }}
                      {...props}
                    />
                  ),
                }}
              >
                {content}
              </ReactMarkdown>
            </Box>
          </Sheet>
          {/* {(isHovered || isLiked || isDisliked) && (
            <Stack
              direction="row"
              spacing={0.5}
              sx={{
                justifyContent: isSent ? "flex-end" : "flex-start",
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                padding: "0.1rem 0.5rem",
                transform: "translateY(100%)",
              }}
            >
              <IconButton
                variant={isLiked ? "soft" : "plain"}
                color={isLiked ? "danger" : "neutral"}
                size="sm"
                onClick={() => setIsLiked((prevState) => !prevState)}
              >
                {isLiked ? "👍" : <ThumbUpIcon />}
              </IconButton>
              <IconButton
                variant={isDisliked ? "soft" : "plain"}
                color={isDisliked ? "warning" : "neutral"}
                size="sm"
                onClick={() => setIsDisliked((prevState) => !prevState)}
              >
                {isDisliked ? "👎" : <ThumbDownIcon />}
              </IconButton>
            </Stack>
          )} */}
        </Box>
      )}
    </Box>
  );
}
