export const storeAuthData = (token: string, user: object) => {
  // Store the token
  localStorage.setItem("token", token);

  // Store the user data as a JSON string
  localStorage.setItem("user", JSON.stringify(user));
};

export const clearAuthData = () => {
  // Remove token and user data from localStorage
  localStorage.removeItem("token");
  localStorage.removeItem("user");
};

export const getUserFromLocalStorage = () => {
  const user = localStorage.getItem("user");
  if (user) {
    return JSON.parse(user);
  }
  return null;
};
