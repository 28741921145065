export const timeAgo = (createdAt: string) => {
  const now = new Date();
  const createdTime = new Date(createdAt);

  const diffInMs = now.getTime() - createdTime.getTime();
  const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
  const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

  // If less than a minute ago, show "Just now"
  if (diffInMinutes < 1) {
    return "Just now";
  }

  // If less than an hour ago, show in minutes
  if (diffInMinutes < 60) {
    return `${diffInMinutes} min ago`;
  }

  // If less than a day ago, show in hours
  if (diffInHours < 24) {
    return `${diffInHours} hours ago`;
  }

  // If it was yesterday, return "Yesterday"
  const isYesterday =
    now.getDate() - createdTime.getDate() === 1 && diffInDays === 1;
  if (isYesterday) {
    return "Yesterday";
  }

  // Otherwise, show the exact date in MM/DD format (you can customize this)
  const month = createdTime.getMonth() + 1; // months are 0-indexed
  const day = createdTime.getDate();

  return `${day}/${month}`;
};

const storeTokenAndUser = (token: string, user: any) => {
  localStorage.setItem("token", token);
  localStorage.setItem("user", JSON.stringify(user));
};
