import * as React from "react";
import AspectRatio from "@mui/joy/AspectRatio";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import Tabs from "@mui/joy/Tabs";
import TabList from "@mui/joy/TabList";
import Tab, { tabClasses } from "@mui/joy/Tab";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Link from "@mui/joy/Link";
import Card from "@mui/joy/Card";
import CardActions from "@mui/joy/CardActions";
import CardOverflow from "@mui/joy/CardOverflow";

import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { getUserFromLocalStorage } from "../api/authUtils";
import { updateProfile } from "../api/apiService";
import Checkbox from "@mui/joy/Checkbox/Checkbox";

export default function Profile() {
  const [profile, setProfile] = React.useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    location: "",
    isDoctor: false,
  });

  React.useEffect(() => {
    const loadProfile = async () => {
      const user = getUserFromLocalStorage();

      setProfile({
        firstName: user?.first_name || "",
        lastName: user?.last_name || "",
        phone: user?.phone_number || "",
        email: user?.email || "",
        location: user?.location || "",
        isDoctor: user?.role === "doctor" || false,
      });
    };
    loadProfile();
  }, []);

  const handleChange =
    (field: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setProfile({ ...profile, [field]: event.target.value });
    };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProfile({ ...profile, isDoctor: event.target.checked });
  };

  const handleSave = async () => {
    await updateProfile({
      first_name: profile.firstName,
      last_name: profile.lastName,
      phone_number: profile.phone,
      email: profile.email,
      location: profile.location,
      role: profile.isDoctor ? "doctor" : "patient",
    });
  };

  return (
    <Box sx={{ flex: 1, width: "100%" }}>
      <Box
        sx={{
          position: "sticky",
          top: { sm: -100, md: -110 },
          bgcolor: "background.body",
          zIndex: 9995,
        }}
      >
        <Box sx={{ px: { xs: 2, md: 6 } }}>
          <Breadcrumbs
            size="sm"
            aria-label="breadcrumbs"
            separator={<ChevronRightRoundedIcon />}
            sx={{ pl: 0 }}
          >
            <Link
              underline="none"
              color="neutral"
              href="#some-link"
              aria-label="Home"
            >
              <HomeRoundedIcon />
            </Link>
            <Link
              underline="hover"
              color="neutral"
              href="#some-link"
              sx={{ fontSize: 12, fontWeight: 500 }}
            >
              Users
            </Link>
            <Typography color="primary" sx={{ fontWeight: 500, fontSize: 12 }}>
              My profile
            </Typography>
          </Breadcrumbs>
          <Typography level="h2" component="h1" sx={{ mt: 1, mb: 2 }}>
            My profile
          </Typography>
        </Box>
        <Tabs defaultValue={0} sx={{ bgcolor: "transparent" }}>
          <TabList
            tabFlex={1}
            size="sm"
            sx={{
              pl: { xs: 0, md: 4 },
              justifyContent: "left",
              [`&& .${tabClasses.root}`]: {
                fontWeight: "600",
                flex: "initial",
                color: "text.tertiary",
                [`&.${tabClasses.selected}`]: {
                  bgcolor: "transparent",
                  color: "text.primary",
                  "&::after": {
                    height: "2px",
                    bgcolor: "primary.500",
                  },
                },
              },
            }}
          >
            <Tab sx={{ borderRadius: "6px 6px 0 0" }} indicatorInset value={0}>
              Settings
            </Tab>
          </TabList>
        </Tabs>
      </Box>
      <Stack
        spacing={4}
        sx={{
          display: "flex",
          maxWidth: "800px",
          mx: "auto",
          px: { xs: 2, md: 6 },
          py: { xs: 2, md: 3 },
        }}
      >
        <Card>
          <Box sx={{ mb: 1 }}>
            <Typography level="title-md">Personal info</Typography>
            <Typography level="body-sm">
              Customize your profile information.
            </Typography>
          </Box>
          <Divider />
          <Stack
            direction="row"
            spacing={3}
            sx={{ display: { xs: "column", md: "flex" }, my: 1 }}
          >
            <Stack direction="column" spacing={1}>
              <AspectRatio
                ratio="1"
                maxHeight={200}
                sx={{ flex: 1, minWidth: 120, borderRadius: "100%" }}
              >
                <img
                  src={`https://api.dicebear.com/9.x/shapes/svg?seed=${profile.email}&radius=50&fit=crop&w=286`}
                  loading="lazy"
                  alt=""
                />
              </AspectRatio>
            </Stack>
            <Stack spacing={2} sx={{ flexGrow: 1 }}>
              <Stack spacing={1}>
                <FormLabel>Name</FormLabel>
                <FormControl
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    gap: 2,
                  }}
                >
                  <Input
                    size="sm"
                    placeholder="First name"
                    sx={{ flex: 1 }}
                    value={profile.firstName}
                    onChange={handleChange("firstName")}
                  />
                  <Input
                    size="sm"
                    placeholder="Last name"
                    sx={{ flex: 1 }}
                    value={profile.lastName}
                    onChange={handleChange("lastName")}
                  />
                </FormControl>
              </Stack>
              <Stack direction="row" spacing={2}>
                <FormControl>
                  <FormLabel>Phone</FormLabel>
                  <Input
                    size="sm"
                    defaultValue=""
                    value={profile.phone}
                    onChange={handleChange("phone")}
                  />
                </FormControl>
                <FormControl sx={{ flexGrow: 1 }}>
                  <FormLabel>Email</FormLabel>
                  <Input
                    size="sm"
                    type="email"
                    startDecorator={<EmailRoundedIcon />}
                    placeholder="test@example.com"
                    defaultValue=""
                    value={profile.email}
                    onChange={handleChange("email")}
                    sx={{ flexGrow: 1 }}
                  />
                </FormControl>
              </Stack>
              <div>
                <FormControl sx={{ display: { sm: "contents" } }}>
                  <FormLabel>Location</FormLabel>
                  <Input
                    size="sm"
                    startDecorator={<LocationOnIcon />}
                    placeholder="New Delhi, India"
                    defaultValue=""
                    value={profile.location}
                    onChange={handleChange("location")}
                    sx={{ flexGrow: 1 }}
                  />
                </FormControl>
              </div>
              <Stack direction="row" spacing={2} alignItems="center">
                <FormControl
                  sx={{ flexDirection: "row", alignItems: "center" }}
                >
                  <Checkbox
                    checked={profile.isDoctor}
                    onChange={handleCheckboxChange}
                    sx={{ mr: 1 }}
                  />
                  <FormLabel>Are you a doctor?</FormLabel>
                </FormControl>
              </Stack>
            </Stack>
          </Stack>
          <CardOverflow sx={{ borderTop: "1px solid", borderColor: "divider" }}>
            <CardActions sx={{ alignSelf: "flex-end", pt: 2 }}>
              <Button size="sm" variant="outlined" color="neutral">
                Cancel
              </Button>
              <Button size="sm" variant="solid" onClick={handleSave}>
                Save
              </Button>
            </CardActions>
          </CardOverflow>
        </Card>
      </Stack>
    </Box>
  );
}
