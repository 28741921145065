import * as React from "react";
import { CssVarsProvider, useColorScheme } from "@mui/joy/styles";
import GlobalStyles from "@mui/joy/GlobalStyles";
import CssBaseline from "@mui/joy/CssBaseline";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import Stack from "@mui/joy/Stack";
import Checkbox from "@mui/joy/Checkbox";
import { signUp } from "../api/apiService";
import { storeAuthData } from "../api/authUtils";
import { useNavigate } from "react-router-dom";

export default function SignUpPage() {
  const navigate = useNavigate();
  const [isDoctor, setIsDoctor] = React.useState(false);

  const handleSignup = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const data = {
      first_name: (formData.get("first_name") as string) || "",
      last_name: (formData.get("last_name") as string) || "",
      email: (formData.get("email") as string) || "",
      phone_number: (formData.get("phone_number") as string) || "",
      password: (formData.get("password") as string) || "",
      role: isDoctor ? "doctor" : "patient",
    };

    const response = await signUp(data);
    storeAuthData(response.token, response.user);
    navigate("/consultations/new");
  };

  return (
    <CssVarsProvider disableTransitionOnChange>
      <CssBaseline />
      <GlobalStyles
        styles={{
          ":root": {
            "--Form-maxWidth": "800px",
            "--Transition-duration": "0.4s", // set to `none` to disable transition
          },
        }}
      />
      <Box
        sx={(theme) => ({
          width: { xs: "100%", md: "50vw" },
          transition: "width var(--Transition-duration)",
          transitionDelay: "calc(var(--Transition-duration) + 0.1s)",
          position: "relative",
          zIndex: 1,
          display: "flex",
          justifyContent: "flex-end",
          backdropFilter: "blur(12px)",
          backgroundColor: "rgba(255 255 255 / 0.2)",
          [theme.getColorSchemeSelector("dark")]: {
            backgroundColor: "rgba(19 19 24 / 0.4)",
          },
        })}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100dvh",
            width: "100%",
            px: 2,
          }}
        >
          <Box
            component="header"
            sx={{ py: 3, display: "flex", justifyContent: "space-between" }}
          >
            <Typography level="h3">CureBot</Typography>
          </Box>

          <Box
            component="main"
            sx={{
              my: "auto",
              py: 2,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: 400,
              maxWidth: "100%",
              mx: "auto",
              borderRadius: "sm",
            }}
          >
            <Typography component="h1" level="h3">
              Sign up
            </Typography>
            <Typography level="body-sm">
              Already have an account?{" "}
              <Link href="/login" level="title-sm">
                Sign in!
              </Link>
            </Typography>

            <form onSubmit={handleSignup}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                {/* First Name and Last Name in the same row */}
                <Stack direction="row" spacing={2}>
                  <FormControl required sx={{ flex: 1 }}>
                    <FormLabel>First Name</FormLabel>
                    <Input type="text" name="first_name" />
                  </FormControl>
                  <FormControl required sx={{ flexGrow: 1 }}>
                    <FormLabel>Last Name</FormLabel>
                    <Input type="text" name="last_name" />
                  </FormControl>
                </Stack>

                {/* Email Field */}
                <FormControl required>
                  <FormLabel>Email</FormLabel>
                  <Input type="email" name="email" />
                </FormControl>

                {/* Phone Number Field */}
                <FormControl>
                  <FormLabel>Phone Number</FormLabel>
                  <Input type="tel" name="phone_number" />
                </FormControl>

                {/* Password Field */}
                <FormControl required>
                  <FormLabel>Password</FormLabel>
                  <Input type="password" name="password" />
                </FormControl>

                <FormControl>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                      size="sm"
                      checked={isDoctor}
                      onChange={(e) => setIsDoctor(e.target.checked)} // Set state on change
                    />
                    <Typography sx={{ marginLeft: 1 }}>
                      Are you a doctor?
                    </Typography>
                  </Box>
                </FormControl>

                {/* Terms and Conditions Checkbox */}
                {/* <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Checkbox size="sm" />
                  <Typography sx={{ marginLeft: 1 }}>
                    I agree to the terms and conditions
                  </Typography>
                </Box> */}

                <Button type="submit" fullWidth>
                  Sign up
                </Button>
              </Box>
            </form>
          </Box>

          <Box component="footer" sx={{ py: 3 }}>
            <Typography level="body-xs" sx={{ textAlign: "center" }}>
              © CureBot {new Date().getFullYear()}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          height: "100%",
          position: "fixed",
          right: 0,
          top: 0,
          bottom: 0,
          left: { xs: 0, md: "50vw" },
          transition:
            "background-image var(--Transition-duration), left var(--Transition-duration) !important",
          transitionDelay: "calc(var(--Transition-duration) + 0.1s)",
          backgroundColor: "background.level1",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundImage:
            "url(https://images.unsplash.com/photo-1638202993928-7267aad84c31?auto=format&w=1000&dpr=2)",
          [theme.getColorSchemeSelector("dark")]: {
            backgroundImage:
              "url(https://images.unsplash.com/photo-1512069511692-b82d787265cf?auto=format&w=1000&dpr=2)",
          },
        })}
      />
    </CssVarsProvider>
  );
}
