import * as React from "react";
import Box from "@mui/joy/Box";
import Sheet from "@mui/joy/Sheet";
import Stack from "@mui/joy/Stack";
import CircularProgress from "@mui/joy/CircularProgress";
import AvatarWithStatus from "./AvatarWithStatus";
import ChatBubble from "./ChatBubble";
import MessageInput from "./MessageInput";
import MessagesPaneHeader from "./MessagesPaneHeader";
import { ChatProps, MessageProps } from "../types";
import { socket } from "../socket";
import { listMessages } from "../api/apiService";
import { uploadFile } from "../api/apiService";

import { getUserFromLocalStorage } from "../api/authUtils";

type MessagesPaneProps = {
  consultations: ChatProps[];
  setConsultations: (chat: ChatProps[]) => void;
  selectedConsultation: ChatProps | null;
  setSelectedConsultation: (chat: ChatProps) => void;
  chat: ChatProps | null;
  chatMessages: MessageProps[];
  setChatMessages: (messages: MessageProps[]) => void;
};

export default function MessagesPane(props: MessagesPaneProps) {
  const {
    consultations,
    setConsultations,
    selectedConsultation,
    setSelectedConsultation,
    chat,
    chatMessages,
    setChatMessages,
  } = props;
  const [textAreaValue, setTextAreaValue] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const handleResponse = (data: string) => {
      // @ts-ignore
      setChatMessages((previousMessages: MessageProps[]) => {
        const lastMessage = previousMessages[previousMessages.length - 1];

        // If the last message isn't from the Assistant, create a new one
        if (!lastMessage || lastMessage.sender !== "Assistant") {
          const newMessageId = String(previousMessages.length + 1);
          return [
            ...previousMessages,
            {
              id: newMessageId,
              sender: "Assistant",
              content: data, // Append new data to the current message
            },
          ];
        } else {
          // Update the content of the last message
          const updatedMessage = previousMessages.map(
            (msg: { sender: string }, index: number) => {
              if (
                index === previousMessages.length - 1 &&
                msg.sender === "Assistant"
              ) {
                return { ...msg, content: data };
              }
              return msg;
            }
          );
          return updatedMessage;
        }
      });
    };

    const handleCompletion = () => {
      setLoading(false);
    };

    const handleConsultationCreation = (data: string) => {
      const parsedConsultation = JSON.parse(data);
      parsedConsultation.createdAt = parsedConsultation.created_at;
      parsedConsultation.lastMessage = parsedConsultation.last_message;
      setConsultations([parsedConsultation, ...consultations]);
      setSelectedConsultation(parsedConsultation);
      window.history.replaceState(
        {},
        "",
        `/consultations/${parsedConsultation.id}`
      );
    };

    socket.on("connect", () => console.log("Connected to server"));
    socket.on("response", handleResponse);
    socket.on("response_end", handleCompletion);
    socket.on("consultation_create", handleConsultationCreation);

    const populateMessages = async () => {
      if (selectedConsultation) {
        const data = await listMessages(selectedConsultation.id);
        setChatMessages(data);
      }
    };
    populateMessages();

    return () => {
      socket.off("response", handleResponse);
      socket.off("response_end", handleCompletion);
      socket.off("consultation_create", handleConsultationCreation);
    };
  }, [chat ? chat.messages : [], selectedConsultation]);

  return (
    <Sheet
      sx={{
        height: { xs: "calc(100dvh - var(--Header-height))", md: "100dvh" },
        display: "flex",
        flexDirection: "column",
        backgroundColor: "background.level1",
      }}
    >
      <MessagesPaneHeader
        sender={{
          name: "CureBot",
          username: "Your personal doctor, always by your side.",
          avatar: "",
          online: true,
        }}
      />
      <Box
        sx={{
          display: "flex",
          flex: 1,
          minHeight: 0,
          px: 2,
          py: 3,
          overflowY: "scroll",
          flexDirection: "column-reverse",
        }}
      >
        <Stack spacing={2} sx={{ justifyContent: "flex-end" }}>
          {chatMessages.map((message: MessageProps, index: number) => {
            const isYou = message.sender === "You";
            return (
              <Stack
                key={index}
                direction="row"
                spacing={2}
                sx={{ flexDirection: isYou ? "row-reverse" : "row" }}
              >
                {message.sender !== "You" && (
                  <AvatarWithStatus online={true} src={"some"} />
                )}
                <ChatBubble
                  variant={isYou ? "sent" : "received"}
                  {...message}
                />
              </Stack>
            );
          })}
          {loading && (
            <Stack direction="row" sx={{ justifyContent: "center" }}>
              <CircularProgress size="sm" color="primary" />
            </Stack>
          )}
        </Stack>
      </Box>
      <MessageInput
        textAreaValue={textAreaValue}
        setTextAreaValue={setTextAreaValue}
        selectedConsultation={selectedConsultation}
        onSubmit={() => {
          setLoading(true);
          const user = getUserFromLocalStorage();

          const newId = chatMessages.length + 1;
          socket.emit(
            "message",
            JSON.stringify({
              query: textAreaValue,
              user_id: user.id,
              consultation_id: selectedConsultation?.id,
            })
          );
          const newIdString = newId.toString();

          setChatMessages([
            ...chatMessages,
            {
              id: newIdString,
              sender: "You",
              content: textAreaValue,
            },
          ]);
        }}
        onFileUpload={async (file) => {
          setLoading(true);
          const user = getUserFromLocalStorage();

          const { uploaded, url } = await uploadFile(file);
          if (!uploaded) {
            setLoading(false);
            return;
          }

          const newId = chatMessages.length + 1;
          socket.emit(
            "message",
            JSON.stringify({
              query: "",
              user_id: user.id,
              consultation_id: selectedConsultation?.id,
              file_name: file.name,
            })
          );
          const newIdString = newId.toString();

          setChatMessages([
            ...chatMessages,
            {
              id: newIdString,
              sender: "You",
              content: textAreaValue,
              attachment: { fileName: file.name, type: file.type, url: url },
            },
          ]);
        }}
      />
    </Sheet>
  );
}
