import axios from "axios";
import apiClient from "./apiClient";
import { toast } from "react-toastify";

export const listConsultations = async () => {
  const response = await apiClient.get("consultations/");
  response.data.forEach((consultation: any) => {
    consultation.createdAt = consultation.created_at;
    consultation.lastMessage = consultation.last_message;
  });
  return response.data;
};

export const retrieveConsultation = async (consultationId: string) => {
  const response = await apiClient.get(`consultations/${consultationId}/`);
  return response.data;
};

export const createConsultation = async (resource: { name: string }) => {
  const response = await apiClient.post("consultations/", resource);
  return response.data;
};

export const addMessage = async (resource: {
  consultationId: string;
  message: string;
  role: string;
}) => {
  const response = await apiClient.post(
    `consultations/${resource.consultationId}/chats/`,
    { message: resource.message, role: resource.role }
  );
  return response.data;
};

export const listMessages = async (consultationId: string) => {
  const response = await apiClient.get(
    `consultations/${consultationId}/chats/`
  );
  response.data.forEach((message: any) => {
    message.sender = message.role === "assistant" ? "Assistant" : "You";
    message.content = message.message;
    message.attachment = {
      fileName: message.attachment?.file_name,
      path: message.attachment?.path,
      url: message.attachment?.url,
    };
  });
  return response.data;
};

export const uploadFile = async (file: File) => {
  try {
    const response = await apiClient.post(`commons/generate-presigned-url/`, {
      file_name: file.name,
      file_type: file.type,
    });

    const { presigned_url, get_presigned_url } = response.data;
    await axios.put(presigned_url, file, {
      headers: { "Content-Type": file.type },
    });
    return { uploaded: true, url: get_presigned_url };
  } catch (error) {
    return { uploaded: false, url: "" };
  }
};

export const signIn = async (resource: { email: string; password: string }) => {
  const response = await apiClient.post("accounts/login/", resource);
  return response.data;
};

export const signUp = async (resource: {
  email: string;
  password: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  role: string;
}) => {
  const response = await apiClient.post(`accounts/signup/`, resource);
  return response.data;
};

export const updateProfile = async (resource: {
  first_name: string;
  last_name: string;
  phone_number: string;
  email: string;
  location: string;
  role: string;
}) => {
  const response = await apiClient.put("accounts/user/", resource);
  toast.success("Profile updated successfully", {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    style: {
      backgroundColor: "#d4edda",
      color: "#155724",
      border: "1px solid #c3e6cb",
      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px",
      fontWeight: "500",
      padding: "10px 20px",
    },
  });

  // Store the user data as a JSON string
  localStorage.setItem("user", JSON.stringify(response.data));
  return response.data;
};
